@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Inter:100,200,300,400,500,600,700,800,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Inter+Tight:100,200,300,400,500,600,700,800,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Mono:100,200,300,400,500,600,700,800,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "ppe-editorial-new-light";
  src: url("./fonts/PPEditorialNew-Light.woff2");
}
@font-face {
  font-family: "PPEditorial new";
  src: url("./fonts/PPEditorialNew-Ultralight.woff2") format("woff2");
}
@font-face {
  font-family: "Really Sans";
  src: url("./fonts/ReallySansLargeStudent-Ultra.woff2") format("woff2");
}
@font-face {
  font-family: "Really Sans Thin";
  src: url("./fonts/ReallySansLargeStudent-Thin.woff") format("woff");
}
@font-face {
  font-family: "Really Sans Small";
  src: url("./fonts/ReallySansSmallStudent-Black.woff2") format("woff2");
}
#root {
  min-height: 100vh;
  height: 100vh;
}

[class|=confetti] {
  position: absolute;
}

.confetti-0 {
  width: 5px;
  height: 2px;
  background-color: #ffbf00;
  top: -10%;
  left: 88%;
  opacity: 0.8406205467;
  transform: rotate(44.4200350236deg);
  animation: drop-0 4.9590618299s 0.4204980739s infinite;
}

@keyframes drop-0 {
  100% {
    top: 110%;
    left: 95%;
  }
}
.confetti-1 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 47%;
  opacity: 0.9587027366;
  transform: rotate(72.8592476088deg);
  animation: drop-1 4.8582076566s 0.6181479051s infinite;
}

@keyframes drop-1 {
  100% {
    top: 110%;
    left: 55%;
  }
}
.confetti-2 {
  width: 7px;
  height: 2.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 67%;
  opacity: 0.782301389;
  transform: rotate(338.0008571117deg);
  animation: drop-2 4.1652520384s 0.1663191246s infinite;
}

@keyframes drop-2 {
  100% {
    top: 110%;
    left: 78%;
  }
}
.confetti-3 {
  width: 1px;
  height: 0.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 83%;
  opacity: 1.3750674677;
  transform: rotate(310.4054289047deg);
  animation: drop-3 4.6108443294s 0.3666606085s infinite;
}

@keyframes drop-3 {
  100% {
    top: 110%;
    left: 86%;
  }
}
.confetti-4 {
  width: 5px;
  height: 2px;
  background-color: #263672;
  top: -10%;
  left: 91%;
  opacity: 1.4182912123;
  transform: rotate(145.5142290597deg);
  animation: drop-4 4.5725863124s 0.5531904066s infinite;
}

@keyframes drop-4 {
  100% {
    top: 110%;
    left: 103%;
  }
}
.confetti-5 {
  width: 5px;
  height: 2px;
  background-color: #263672;
  top: -10%;
  left: 91%;
  opacity: 0.8468683985;
  transform: rotate(206.9773236537deg);
  animation: drop-5 4.1074762123s 0.0451664344s infinite;
}

@keyframes drop-5 {
  100% {
    top: 110%;
    left: 100%;
  }
}
.confetti-6 {
  width: 2px;
  height: 0.8px;
  background-color: #d13447;
  top: -10%;
  left: 63%;
  opacity: 0.6390570552;
  transform: rotate(259.7542837917deg);
  animation: drop-6 4.6011796011s 0.3330036944s infinite;
}

@keyframes drop-6 {
  100% {
    top: 110%;
    left: 70%;
  }
}
.confetti-7 {
  width: 6px;
  height: 2.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 40%;
  opacity: 0.5088283846;
  transform: rotate(196.8427778444deg);
  animation: drop-7 4.5028133434s 0.9049153239s infinite;
}

@keyframes drop-7 {
  100% {
    top: 110%;
    left: 54%;
  }
}
.confetti-8 {
  width: 5px;
  height: 2px;
  background-color: #ffbf00;
  top: -10%;
  left: 49%;
  opacity: 1.3537693338;
  transform: rotate(306.1537473318deg);
  animation: drop-8 4.9924107777s 0.362598119s infinite;
}

@keyframes drop-8 {
  100% {
    top: 110%;
    left: 57%;
  }
}
.confetti-9 {
  width: 3px;
  height: 1.2px;
  background-color: #263672;
  top: -10%;
  left: 75%;
  opacity: 1.3536164904;
  transform: rotate(163.2549661675deg);
  animation: drop-9 4.0954435724s 0.4658655055s infinite;
}

@keyframes drop-9 {
  100% {
    top: 110%;
    left: 89%;
  }
}
.confetti-10 {
  width: 8px;
  height: 3.2px;
  background-color: #263672;
  top: -10%;
  left: 73%;
  opacity: 0.5469683356;
  transform: rotate(294.5961179604deg);
  animation: drop-10 4.5397491862s 0.5747641066s infinite;
}

@keyframes drop-10 {
  100% {
    top: 110%;
    left: 74%;
  }
}
.confetti-11 {
  width: 7px;
  height: 2.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 17%;
  opacity: 1.3293424868;
  transform: rotate(144.1545251078deg);
  animation: drop-11 4.8721585784s 0.7664565434s infinite;
}

@keyframes drop-11 {
  100% {
    top: 110%;
    left: 26%;
  }
}
.confetti-12 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 90%;
  opacity: 1.2576764106;
  transform: rotate(172.2886447035deg);
  animation: drop-12 4.2668787237s 0.22775686s infinite;
}

@keyframes drop-12 {
  100% {
    top: 110%;
    left: 99%;
  }
}
.confetti-13 {
  width: 7px;
  height: 2.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 74%;
  opacity: 1.1914472681;
  transform: rotate(187.236999407deg);
  animation: drop-13 4.6157098607s 0.9656126422s infinite;
}

@keyframes drop-13 {
  100% {
    top: 110%;
    left: 83%;
  }
}
.confetti-14 {
  width: 8px;
  height: 3.2px;
  background-color: #263672;
  top: -10%;
  left: 27%;
  opacity: 1.0661522722;
  transform: rotate(259.3702101305deg);
  animation: drop-14 4.294284271s 0.5595863445s infinite;
}

@keyframes drop-14 {
  100% {
    top: 110%;
    left: 29%;
  }
}
.confetti-15 {
  width: 8px;
  height: 3.2px;
  background-color: #263672;
  top: -10%;
  left: 84%;
  opacity: 1.1444467536;
  transform: rotate(217.1067490655deg);
  animation: drop-15 4.0233099737s 0.2231636824s infinite;
}

@keyframes drop-15 {
  100% {
    top: 110%;
    left: 93%;
  }
}
.confetti-16 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 51%;
  opacity: 0.5271553656;
  transform: rotate(183.0147918881deg);
  animation: drop-16 4.8800486799s 0.9977449905s infinite;
}

@keyframes drop-16 {
  100% {
    top: 110%;
    left: 55%;
  }
}
.confetti-17 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 40%;
  opacity: 0.5448442269;
  transform: rotate(318.708830965deg);
  animation: drop-17 4.5450534086s 0.4073884494s infinite;
}

@keyframes drop-17 {
  100% {
    top: 110%;
    left: 48%;
  }
}
.confetti-18 {
  width: 5px;
  height: 2px;
  background-color: #d13447;
  top: -10%;
  left: 6%;
  opacity: 1.3127048332;
  transform: rotate(97.4756180819deg);
  animation: drop-18 4.2959680499s 0.2742230009s infinite;
}

@keyframes drop-18 {
  100% {
    top: 110%;
    left: 7%;
  }
}
.confetti-19 {
  width: 7px;
  height: 2.8px;
  background-color: #d13447;
  top: -10%;
  left: 86%;
  opacity: 1.4766601615;
  transform: rotate(322.0677790797deg);
  animation: drop-19 4.0597107486s 0.5012994307s infinite;
}

@keyframes drop-19 {
  100% {
    top: 110%;
    left: 100%;
  }
}
.confetti-20 {
  width: 3px;
  height: 1.2px;
  background-color: #263672;
  top: -10%;
  left: 33%;
  opacity: 0.5586244217;
  transform: rotate(117.5923151169deg);
  animation: drop-20 4.470949309s 0.4524294552s infinite;
}

@keyframes drop-20 {
  100% {
    top: 110%;
    left: 41%;
  }
}
.confetti-21 {
  width: 2px;
  height: 0.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 88%;
  opacity: 1.3853078735;
  transform: rotate(257.4840809694deg);
  animation: drop-21 4.2991742013s 0.1627023007s infinite;
}

@keyframes drop-21 {
  100% {
    top: 110%;
    left: 90%;
  }
}
.confetti-22 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 12%;
  opacity: 1.4970836581;
  transform: rotate(217.3437136888deg);
  animation: drop-22 4.8140716207s 0.751569574s infinite;
}

@keyframes drop-22 {
  100% {
    top: 110%;
    left: 21%;
  }
}
.confetti-23 {
  width: 6px;
  height: 2.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 37%;
  opacity: 0.7252454823;
  transform: rotate(202.2432837334deg);
  animation: drop-23 4.9743308197s 0.5617712706s infinite;
}

@keyframes drop-23 {
  100% {
    top: 110%;
    left: 42%;
  }
}
.confetti-24 {
  width: 8px;
  height: 3.2px;
  background-color: #263672;
  top: -10%;
  left: 63%;
  opacity: 0.9919844114;
  transform: rotate(210.4232489154deg);
  animation: drop-24 4.2978064587s 0.8646419071s infinite;
}

@keyframes drop-24 {
  100% {
    top: 110%;
    left: 64%;
  }
}
.confetti-25 {
  width: 1px;
  height: 0.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 19%;
  opacity: 1.4922847131;
  transform: rotate(44.7069062634deg);
  animation: drop-25 4.1060723043s 0.9687275826s infinite;
}

@keyframes drop-25 {
  100% {
    top: 110%;
    left: 22%;
  }
}
.confetti-26 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 14%;
  opacity: 0.994150899;
  transform: rotate(335.7680331152deg);
  animation: drop-26 4.5855090644s 0.54166364s infinite;
}

@keyframes drop-26 {
  100% {
    top: 110%;
    left: 24%;
  }
}
.confetti-27 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 59%;
  opacity: 0.8018622394;
  transform: rotate(285.3060443402deg);
  animation: drop-27 4.7406566414s 0.0445205551s infinite;
}

@keyframes drop-27 {
  100% {
    top: 110%;
    left: 73%;
  }
}
.confetti-28 {
  width: 1px;
  height: 0.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 90%;
  opacity: 0.818047958;
  transform: rotate(74.0229218422deg);
  animation: drop-28 4.5172662594s 0.454207035s infinite;
}

@keyframes drop-28 {
  100% {
    top: 110%;
    left: 95%;
  }
}
.confetti-29 {
  width: 8px;
  height: 3.2px;
  background-color: #263672;
  top: -10%;
  left: 99%;
  opacity: 0.8522242857;
  transform: rotate(315.8085413914deg);
  animation: drop-29 4.7798990594s 0.9674095887s infinite;
}

@keyframes drop-29 {
  100% {
    top: 110%;
    left: 101%;
  }
}
.confetti-30 {
  width: 5px;
  height: 2px;
  background-color: #d13447;
  top: -10%;
  left: 24%;
  opacity: 0.7425717118;
  transform: rotate(352.9082748419deg);
  animation: drop-30 4.8794284043s 0.9366902058s infinite;
}

@keyframes drop-30 {
  100% {
    top: 110%;
    left: 26%;
  }
}
.confetti-31 {
  width: 5px;
  height: 2px;
  background-color: #d13447;
  top: -10%;
  left: 10%;
  opacity: 1.4158798297;
  transform: rotate(10.9696180555deg);
  animation: drop-31 4.1393763787s 0.8618282046s infinite;
}

@keyframes drop-31 {
  100% {
    top: 110%;
    left: 20%;
  }
}
.confetti-32 {
  width: 8px;
  height: 3.2px;
  background-color: #263672;
  top: -10%;
  left: 1%;
  opacity: 0.8937763235;
  transform: rotate(262.5378887432deg);
  animation: drop-32 4.9463139978s 0.1137760085s infinite;
}

@keyframes drop-32 {
  100% {
    top: 110%;
    left: 13%;
  }
}
.confetti-33 {
  width: 8px;
  height: 3.2px;
  background-color: #263672;
  top: -10%;
  left: 20%;
  opacity: 0.7979239818;
  transform: rotate(304.6969005079deg);
  animation: drop-33 4.963819788s 0.1447973303s infinite;
}

@keyframes drop-33 {
  100% {
    top: 110%;
    left: 24%;
  }
}
.confetti-34 {
  width: 7px;
  height: 2.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 86%;
  opacity: 1.0016814619;
  transform: rotate(268.2312723339deg);
  animation: drop-34 4.0422231202s 0.8020002214s infinite;
}

@keyframes drop-34 {
  100% {
    top: 110%;
    left: 97%;
  }
}
.confetti-35 {
  width: 8px;
  height: 3.2px;
  background-color: #d13447;
  top: -10%;
  left: 64%;
  opacity: 0.5288923869;
  transform: rotate(296.7998861435deg);
  animation: drop-35 4.7348353157s 0.6948158586s infinite;
}

@keyframes drop-35 {
  100% {
    top: 110%;
    left: 68%;
  }
}
.confetti-36 {
  width: 1px;
  height: 0.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 99%;
  opacity: 1.4539411503;
  transform: rotate(11.5853245353deg);
  animation: drop-36 4.5616189781s 0.1293919864s infinite;
}

@keyframes drop-36 {
  100% {
    top: 110%;
    left: 104%;
  }
}
.confetti-37 {
  width: 5px;
  height: 2px;
  background-color: #ffbf00;
  top: -10%;
  left: 39%;
  opacity: 0.9619966406;
  transform: rotate(281.8551020277deg);
  animation: drop-37 4.575967333s 0.3672854924s infinite;
}

@keyframes drop-37 {
  100% {
    top: 110%;
    left: 54%;
  }
}
.confetti-38 {
  width: 6px;
  height: 2.4px;
  background-color: #d13447;
  top: -10%;
  left: 32%;
  opacity: 1.1265882388;
  transform: rotate(184.6289783152deg);
  animation: drop-38 4.5758571254s 0.4773017662s infinite;
}

@keyframes drop-38 {
  100% {
    top: 110%;
    left: 33%;
  }
}
.confetti-39 {
  width: 2px;
  height: 0.8px;
  background-color: #263672;
  top: -10%;
  left: 53%;
  opacity: 1.4825709643;
  transform: rotate(352.897888642deg);
  animation: drop-39 4.0248131785s 0.0229011744s infinite;
}

@keyframes drop-39 {
  100% {
    top: 110%;
    left: 59%;
  }
}
.confetti-40 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 71%;
  opacity: 0.5797152454;
  transform: rotate(260.6688108571deg);
  animation: drop-40 4.5015367481s 0.4707978078s infinite;
}

@keyframes drop-40 {
  100% {
    top: 110%;
    left: 80%;
  }
}
.confetti-41 {
  width: 8px;
  height: 3.2px;
  background-color: #d13447;
  top: -10%;
  left: 74%;
  opacity: 1.3063934307;
  transform: rotate(293.6263667984deg);
  animation: drop-41 4.9809931393s 0.5558629807s infinite;
}

@keyframes drop-41 {
  100% {
    top: 110%;
    left: 89%;
  }
}
.confetti-42 {
  width: 2px;
  height: 0.8px;
  background-color: #263672;
  top: -10%;
  left: 84%;
  opacity: 0.9636015059;
  transform: rotate(216.9633865726deg);
  animation: drop-42 4.4276954539s 0.0215150056s infinite;
}

@keyframes drop-42 {
  100% {
    top: 110%;
    left: 92%;
  }
}
.confetti-43 {
  width: 3px;
  height: 1.2px;
  background-color: #263672;
  top: -10%;
  left: 61%;
  opacity: 1.0821105123;
  transform: rotate(200.715058333deg);
  animation: drop-43 4.7357960529s 0.0127289526s infinite;
}

@keyframes drop-43 {
  100% {
    top: 110%;
    left: 66%;
  }
}
.confetti-44 {
  width: 8px;
  height: 3.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 48%;
  opacity: 0.9232218099;
  transform: rotate(261.8358002057deg);
  animation: drop-44 4.6505553056s 0.8832433217s infinite;
}

@keyframes drop-44 {
  100% {
    top: 110%;
    left: 52%;
  }
}
.confetti-45 {
  width: 8px;
  height: 3.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 30%;
  opacity: 1.3651554711;
  transform: rotate(254.2601578693deg);
  animation: drop-45 4.8496069539s 0.8685544375s infinite;
}

@keyframes drop-45 {
  100% {
    top: 110%;
    left: 38%;
  }
}
.confetti-46 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 63%;
  opacity: 0.9900652794;
  transform: rotate(216.231795342deg);
  animation: drop-46 4.1740891673s 0.1645078209s infinite;
}

@keyframes drop-46 {
  100% {
    top: 110%;
    left: 67%;
  }
}
.confetti-47 {
  width: 3px;
  height: 1.2px;
  background-color: #263672;
  top: -10%;
  left: 69%;
  opacity: 0.8644563697;
  transform: rotate(182.7969715606deg);
  animation: drop-47 4.4200242419s 0.8467145202s infinite;
}

@keyframes drop-47 {
  100% {
    top: 110%;
    left: 78%;
  }
}
.confetti-48 {
  width: 8px;
  height: 3.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 68%;
  opacity: 0.5097447178;
  transform: rotate(272.7115469601deg);
  animation: drop-48 4.251365723s 0.4330986661s infinite;
}

@keyframes drop-48 {
  100% {
    top: 110%;
    left: 82%;
  }
}
.confetti-49 {
  width: 1px;
  height: 0.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 3%;
  opacity: 1.2837182042;
  transform: rotate(145.7003734968deg);
  animation: drop-49 4.3094574064s 0.9687546271s infinite;
}

@keyframes drop-49 {
  100% {
    top: 110%;
    left: 5%;
  }
}
.confetti-50 {
  width: 8px;
  height: 3.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 72%;
  opacity: 0.5796321949;
  transform: rotate(41.2496485911deg);
  animation: drop-50 4.8181060856s 0.9881358264s infinite;
}

@keyframes drop-50 {
  100% {
    top: 110%;
    left: 75%;
  }
}
.confetti-51 {
  width: 2px;
  height: 0.8px;
  background-color: #d13447;
  top: -10%;
  left: 11%;
  opacity: 1.119091881;
  transform: rotate(183.1618343939deg);
  animation: drop-51 4.54483683s 0.8906041285s infinite;
}

@keyframes drop-51 {
  100% {
    top: 110%;
    left: 22%;
  }
}
.confetti-52 {
  width: 3px;
  height: 1.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 85%;
  opacity: 1.0097175266;
  transform: rotate(331.4570031313deg);
  animation: drop-52 4.1566325733s 0.7421067349s infinite;
}

@keyframes drop-52 {
  100% {
    top: 110%;
    left: 97%;
  }
}
.confetti-53 {
  width: 5px;
  height: 2px;
  background-color: #ffbf00;
  top: -10%;
  left: 26%;
  opacity: 0.5703024025;
  transform: rotate(30.099380798deg);
  animation: drop-53 4.1261581354s 0.3613027825s infinite;
}

@keyframes drop-53 {
  100% {
    top: 110%;
    left: 38%;
  }
}
.confetti-54 {
  width: 7px;
  height: 2.8px;
  background-color: #d13447;
  top: -10%;
  left: 60%;
  opacity: 0.5141741544;
  transform: rotate(353.3591396585deg);
  animation: drop-54 4.4261490805s 0.5627381831s infinite;
}

@keyframes drop-54 {
  100% {
    top: 110%;
    left: 64%;
  }
}
.confetti-55 {
  width: 2px;
  height: 0.8px;
  background-color: #263672;
  top: -10%;
  left: 35%;
  opacity: 1.2020540582;
  transform: rotate(306.5493496561deg);
  animation: drop-55 4.3889763401s 0.3137347597s infinite;
}

@keyframes drop-55 {
  100% {
    top: 110%;
    left: 41%;
  }
}
.confetti-56 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 67%;
  opacity: 1.2288489077;
  transform: rotate(30.5068902899deg);
  animation: drop-56 4.3173379889s 0.9621792673s infinite;
}

@keyframes drop-56 {
  100% {
    top: 110%;
    left: 82%;
  }
}
.confetti-57 {
  width: 2px;
  height: 0.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 82%;
  opacity: 1.1046349331;
  transform: rotate(145.116065991deg);
  animation: drop-57 4.9681158113s 0.5552312279s infinite;
}

@keyframes drop-57 {
  100% {
    top: 110%;
    left: 84%;
  }
}
.confetti-58 {
  width: 5px;
  height: 2px;
  background-color: #263672;
  top: -10%;
  left: 44%;
  opacity: 0.8183662688;
  transform: rotate(66.1836373962deg);
  animation: drop-58 4.3249672864s 0.2655723392s infinite;
}

@keyframes drop-58 {
  100% {
    top: 110%;
    left: 58%;
  }
}
.confetti-59 {
  width: 8px;
  height: 3.2px;
  background-color: #263672;
  top: -10%;
  left: 80%;
  opacity: 0.9195849566;
  transform: rotate(304.709512003deg);
  animation: drop-59 4.0006659869s 0.8082884655s infinite;
}

@keyframes drop-59 {
  100% {
    top: 110%;
    left: 85%;
  }
}
.confetti-60 {
  width: 4px;
  height: 1.6px;
  background-color: #263672;
  top: -10%;
  left: 36%;
  opacity: 1.1686434686;
  transform: rotate(37.7501991112deg);
  animation: drop-60 4.4113189991s 0.4083073265s infinite;
}

@keyframes drop-60 {
  100% {
    top: 110%;
    left: 44%;
  }
}
.confetti-61 {
  width: 8px;
  height: 3.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 10%;
  opacity: 1.203034841;
  transform: rotate(310.4567705218deg);
  animation: drop-61 4.604484434s 0.1931895572s infinite;
}

@keyframes drop-61 {
  100% {
    top: 110%;
    left: 14%;
  }
}
.confetti-62 {
  width: 6px;
  height: 2.4px;
  background-color: #d13447;
  top: -10%;
  left: 42%;
  opacity: 0.957133988;
  transform: rotate(21.9222404356deg);
  animation: drop-62 4.7315692612s 0.2197658478s infinite;
}

@keyframes drop-62 {
  100% {
    top: 110%;
    left: 55%;
  }
}
.confetti-63 {
  width: 6px;
  height: 2.4px;
  background-color: #d13447;
  top: -10%;
  left: 87%;
  opacity: 1.2661202175;
  transform: rotate(268.226723155deg);
  animation: drop-63 4.6097988964s 0.2177336314s infinite;
}

@keyframes drop-63 {
  100% {
    top: 110%;
    left: 102%;
  }
}
.confetti-64 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 29%;
  opacity: 0.7852891936;
  transform: rotate(39.2225481746deg);
  animation: drop-64 4.1106490263s 0.9001834252s infinite;
}

@keyframes drop-64 {
  100% {
    top: 110%;
    left: 30%;
  }
}
.confetti-65 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 17%;
  opacity: 1.2386214534;
  transform: rotate(343.9848361282deg);
  animation: drop-65 4.8536312073s 0.1435662559s infinite;
}

@keyframes drop-65 {
  100% {
    top: 110%;
    left: 29%;
  }
}
.confetti-66 {
  width: 1px;
  height: 0.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 61%;
  opacity: 0.8672507405;
  transform: rotate(111.6182402614deg);
  animation: drop-66 4.6651192583s 0.1995061441s infinite;
}

@keyframes drop-66 {
  100% {
    top: 110%;
    left: 76%;
  }
}
.confetti-67 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 72%;
  opacity: 0.5832751211;
  transform: rotate(300.571901596deg);
  animation: drop-67 4.0701961299s 0.4176725962s infinite;
}

@keyframes drop-67 {
  100% {
    top: 110%;
    left: 81%;
  }
}
.confetti-68 {
  width: 1px;
  height: 0.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 26%;
  opacity: 0.5122684824;
  transform: rotate(357.0120881598deg);
  animation: drop-68 4.9469646274s 0.3718655485s infinite;
}

@keyframes drop-68 {
  100% {
    top: 110%;
    left: 35%;
  }
}
.confetti-69 {
  width: 7px;
  height: 2.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 88%;
  opacity: 1.0142707976;
  transform: rotate(134.8300249047deg);
  animation: drop-69 4.0365159312s 0.7047877176s infinite;
}

@keyframes drop-69 {
  100% {
    top: 110%;
    left: 97%;
  }
}
.confetti-70 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 71%;
  opacity: 0.6959609171;
  transform: rotate(7.0404960955deg);
  animation: drop-70 4.6606232665s 0.5462867207s infinite;
}

@keyframes drop-70 {
  100% {
    top: 110%;
    left: 85%;
  }
}
.confetti-71 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 1%;
  opacity: 0.5897354215;
  transform: rotate(255.8934425623deg);
  animation: drop-71 4.535817762s 0.1569867232s infinite;
}

@keyframes drop-71 {
  100% {
    top: 110%;
    left: 12%;
  }
}
.confetti-72 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 90%;
  opacity: 0.8954452814;
  transform: rotate(260.0433892763deg);
  animation: drop-72 4.6910573753s 0.1432368963s infinite;
}

@keyframes drop-72 {
  100% {
    top: 110%;
    left: 95%;
  }
}
.confetti-73 {
  width: 8px;
  height: 3.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 73%;
  opacity: 0.9338389626;
  transform: rotate(17.3054827874deg);
  animation: drop-73 4.3858588941s 0.8968877248s infinite;
}

@keyframes drop-73 {
  100% {
    top: 110%;
    left: 80%;
  }
}
.confetti-74 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 64%;
  opacity: 0.8561980466;
  transform: rotate(329.6154395233deg);
  animation: drop-74 4.1196000203s 0.0995935116s infinite;
}

@keyframes drop-74 {
  100% {
    top: 110%;
    left: 78%;
  }
}
.confetti-75 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 96%;
  opacity: 1.0102863702;
  transform: rotate(19.4682829741deg);
  animation: drop-75 4.0026792958s 0.1175242753s infinite;
}

@keyframes drop-75 {
  100% {
    top: 110%;
    left: 105%;
  }
}
.confetti-76 {
  width: 6px;
  height: 2.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 22%;
  opacity: 1.2147994706;
  transform: rotate(348.1609493458deg);
  animation: drop-76 4.6846125043s 0.0968034566s infinite;
}

@keyframes drop-76 {
  100% {
    top: 110%;
    left: 30%;
  }
}
.confetti-77 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 11%;
  opacity: 0.9250133605;
  transform: rotate(144.4741304636deg);
  animation: drop-77 4.0916770832s 0.8201208861s infinite;
}

@keyframes drop-77 {
  100% {
    top: 110%;
    left: 21%;
  }
}
.confetti-78 {
  width: 4px;
  height: 1.6px;
  background-color: #ffbf00;
  top: -10%;
  left: 42%;
  opacity: 0.6927392166;
  transform: rotate(195.8456664181deg);
  animation: drop-78 4.6902856876s 0.3535370088s infinite;
}

@keyframes drop-78 {
  100% {
    top: 110%;
    left: 51%;
  }
}
.confetti-79 {
  width: 2px;
  height: 0.8px;
  background-color: #d13447;
  top: -10%;
  left: 98%;
  opacity: 0.9729229563;
  transform: rotate(52.3869041238deg);
  animation: drop-79 4.0334089198s 0.1043823024s infinite;
}

@keyframes drop-79 {
  100% {
    top: 110%;
    left: 102%;
  }
}
.confetti-80 {
  width: 8px;
  height: 3.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 60%;
  opacity: 1.0874013707;
  transform: rotate(197.9340901772deg);
  animation: drop-80 4.0941699755s 0.526283988s infinite;
}

@keyframes drop-80 {
  100% {
    top: 110%;
    left: 71%;
  }
}
.confetti-81 {
  width: 2px;
  height: 0.8px;
  background-color: #263672;
  top: -10%;
  left: 8%;
  opacity: 0.7816325851;
  transform: rotate(281.0827273255deg);
  animation: drop-81 4.5578979259s 0.8377933052s infinite;
}

@keyframes drop-81 {
  100% {
    top: 110%;
    left: 22%;
  }
}
.confetti-82 {
  width: 7px;
  height: 2.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 65%;
  opacity: 1.3672594912;
  transform: rotate(23.8306903137deg);
  animation: drop-82 4.6558903745s 0.734511427s infinite;
}

@keyframes drop-82 {
  100% {
    top: 110%;
    left: 80%;
  }
}
.confetti-83 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 66%;
  opacity: 0.9304711138;
  transform: rotate(26.9536996962deg);
  animation: drop-83 4.0902258651s 0.7426551255s infinite;
}

@keyframes drop-83 {
  100% {
    top: 110%;
    left: 72%;
  }
}
.confetti-84 {
  width: 6px;
  height: 2.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 15%;
  opacity: 1.3996525421;
  transform: rotate(263.9340509365deg);
  animation: drop-84 4.1912670643s 0.4633111943s infinite;
}

@keyframes drop-84 {
  100% {
    top: 110%;
    left: 18%;
  }
}
.confetti-85 {
  width: 8px;
  height: 3.2px;
  background-color: #263672;
  top: -10%;
  left: 3%;
  opacity: 1.2064425184;
  transform: rotate(118.9953730626deg);
  animation: drop-85 4.7497449367s 0.5929784164s infinite;
}

@keyframes drop-85 {
  100% {
    top: 110%;
    left: 9%;
  }
}
.confetti-86 {
  width: 3px;
  height: 1.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 75%;
  opacity: 0.8095362244;
  transform: rotate(49.0655854377deg);
  animation: drop-86 4.4141309298s 0.4312043369s infinite;
}

@keyframes drop-86 {
  100% {
    top: 110%;
    left: 84%;
  }
}
.confetti-87 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 20%;
  opacity: 1.418963225;
  transform: rotate(334.2397317389deg);
  animation: drop-87 4.2853362932s 0.0746029279s infinite;
}

@keyframes drop-87 {
  100% {
    top: 110%;
    left: 23%;
  }
}
.confetti-88 {
  width: 3px;
  height: 1.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 19%;
  opacity: 0.8915320777;
  transform: rotate(157.9470793331deg);
  animation: drop-88 4.2989345011s 0.7563701856s infinite;
}

@keyframes drop-88 {
  100% {
    top: 110%;
    left: 23%;
  }
}
.confetti-89 {
  width: 3px;
  height: 1.2px;
  background-color: #263672;
  top: -10%;
  left: 58%;
  opacity: 0.8354765221;
  transform: rotate(190.1479784927deg);
  animation: drop-89 4.6493766852s 0.2069919212s infinite;
}

@keyframes drop-89 {
  100% {
    top: 110%;
    left: 66%;
  }
}
.confetti-90 {
  width: 5px;
  height: 2px;
  background-color: #263672;
  top: -10%;
  left: 37%;
  opacity: 0.862467278;
  transform: rotate(302.5121219131deg);
  animation: drop-90 4.2597554669s 0.7104997786s infinite;
}

@keyframes drop-90 {
  100% {
    top: 110%;
    left: 49%;
  }
}
.confetti-91 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 38%;
  opacity: 1.4478556431;
  transform: rotate(166.4880268522deg);
  animation: drop-91 4.0798614699s 0.3509234468s infinite;
}

@keyframes drop-91 {
  100% {
    top: 110%;
    left: 52%;
  }
}
.confetti-92 {
  width: 4px;
  height: 1.6px;
  background-color: #ffbf00;
  top: -10%;
  left: 83%;
  opacity: 0.7159316163;
  transform: rotate(212.5879804076deg);
  animation: drop-92 4.3177035978s 0.9923649097s infinite;
}

@keyframes drop-92 {
  100% {
    top: 110%;
    left: 97%;
  }
}
.confetti-93 {
  width: 7px;
  height: 2.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 90%;
  opacity: 1.3215746214;
  transform: rotate(86.8579397603deg);
  animation: drop-93 4.0240774051s 0.1482564351s infinite;
}

@keyframes drop-93 {
  100% {
    top: 110%;
    left: 97%;
  }
}
.confetti-94 {
  width: 5px;
  height: 2px;
  background-color: #ffbf00;
  top: -10%;
  left: 83%;
  opacity: 0.9647384624;
  transform: rotate(220.6457427766deg);
  animation: drop-94 4.7168019254s 0.3056492085s infinite;
}

@keyframes drop-94 {
  100% {
    top: 110%;
    left: 92%;
  }
}
.confetti-95 {
  width: 8px;
  height: 3.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 15%;
  opacity: 0.8332360635;
  transform: rotate(195.6898259687deg);
  animation: drop-95 4.8155906269s 0.3303737712s infinite;
}

@keyframes drop-95 {
  100% {
    top: 110%;
    left: 16%;
  }
}
.confetti-96 {
  width: 6px;
  height: 2.4px;
  background-color: #d13447;
  top: -10%;
  left: 35%;
  opacity: 1.3713797496;
  transform: rotate(73.3930914954deg);
  animation: drop-96 4.5398354982s 0.986354382s infinite;
}

@keyframes drop-96 {
  100% {
    top: 110%;
    left: 41%;
  }
}
.confetti-97 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 63%;
  opacity: 1.0505180657;
  transform: rotate(239.0870221219deg);
  animation: drop-97 4.7323662776s 0.159815219s infinite;
}

@keyframes drop-97 {
  100% {
    top: 110%;
    left: 66%;
  }
}
.confetti-98 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 29%;
  opacity: 1.3360589644;
  transform: rotate(210.5897278786deg);
  animation: drop-98 4.4853735266s 0.092316269s infinite;
}

@keyframes drop-98 {
  100% {
    top: 110%;
    left: 40%;
  }
}
.confetti-99 {
  width: 5px;
  height: 2px;
  background-color: #d13447;
  top: -10%;
  left: 95%;
  opacity: 0.6425930164;
  transform: rotate(332.6145542459deg);
  animation: drop-99 4.2390898416s 0.9821093282s infinite;
}

@keyframes drop-99 {
  100% {
    top: 110%;
    left: 106%;
  }
}
.confetti-100 {
  width: 7px;
  height: 2.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 99%;
  opacity: 0.5957233578;
  transform: rotate(209.0705953158deg);
  animation: drop-100 4.0189088029s 0.3262308556s infinite;
}

@keyframes drop-100 {
  100% {
    top: 110%;
    left: 106%;
  }
}
.confetti-101 {
  width: 7px;
  height: 2.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 38%;
  opacity: 1.4253660182;
  transform: rotate(48.2058892725deg);
  animation: drop-101 4.568931743s 0.8855852267s infinite;
}

@keyframes drop-101 {
  100% {
    top: 110%;
    left: 40%;
  }
}
.confetti-102 {
  width: 8px;
  height: 3.2px;
  background-color: #263672;
  top: -10%;
  left: 31%;
  opacity: 0.5397533189;
  transform: rotate(284.3493229815deg);
  animation: drop-102 4.9383295999s 0.3881240307s infinite;
}

@keyframes drop-102 {
  100% {
    top: 110%;
    left: 41%;
  }
}
.confetti-103 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 69%;
  opacity: 0.5324691171;
  transform: rotate(279.8653202799deg);
  animation: drop-103 4.1412504815s 0.6948528675s infinite;
}

@keyframes drop-103 {
  100% {
    top: 110%;
    left: 82%;
  }
}
.confetti-104 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 23%;
  opacity: 0.8959527946;
  transform: rotate(334.9043597361deg);
  animation: drop-104 4.3855429784s 0.1677856028s infinite;
}

@keyframes drop-104 {
  100% {
    top: 110%;
    left: 35%;
  }
}
.confetti-105 {
  width: 3px;
  height: 1.2px;
  background-color: #263672;
  top: -10%;
  left: 23%;
  opacity: 1.4493112774;
  transform: rotate(211.6425798772deg);
  animation: drop-105 4.1023820041s 0.2328166623s infinite;
}

@keyframes drop-105 {
  100% {
    top: 110%;
    left: 31%;
  }
}
.confetti-106 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 36%;
  opacity: 0.7002990273;
  transform: rotate(272.8424222658deg);
  animation: drop-106 4.9010474793s 0.5135547704s infinite;
}

@keyframes drop-106 {
  100% {
    top: 110%;
    left: 44%;
  }
}
.confetti-107 {
  width: 2px;
  height: 0.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 47%;
  opacity: 0.5131561808;
  transform: rotate(181.964103067deg);
  animation: drop-107 4.8321959186s 0.4639799756s infinite;
}

@keyframes drop-107 {
  100% {
    top: 110%;
    left: 60%;
  }
}
.confetti-108 {
  width: 8px;
  height: 3.2px;
  background-color: #263672;
  top: -10%;
  left: 32%;
  opacity: 1.2908241453;
  transform: rotate(4.7303086891deg);
  animation: drop-108 4.1866661193s 0.6259498059s infinite;
}

@keyframes drop-108 {
  100% {
    top: 110%;
    left: 36%;
  }
}
.confetti-109 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 100%;
  opacity: 0.7398501948;
  transform: rotate(27.0716507592deg);
  animation: drop-109 4.8758283621s 0.353252728s infinite;
}

@keyframes drop-109 {
  100% {
    top: 110%;
    left: 103%;
  }
}
.confetti-110 {
  width: 6px;
  height: 2.4px;
  background-color: #d13447;
  top: -10%;
  left: 64%;
  opacity: 1.2088998895;
  transform: rotate(176.1038012285deg);
  animation: drop-110 4.4936254873s 0.4329005991s infinite;
}

@keyframes drop-110 {
  100% {
    top: 110%;
    left: 71%;
  }
}
.confetti-111 {
  width: 8px;
  height: 3.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 26%;
  opacity: 1.1657376765;
  transform: rotate(280.7082467456deg);
  animation: drop-111 4.9545011044s 0.8828888916s infinite;
}

@keyframes drop-111 {
  100% {
    top: 110%;
    left: 27%;
  }
}
.confetti-112 {
  width: 4px;
  height: 1.6px;
  background-color: #263672;
  top: -10%;
  left: 83%;
  opacity: 0.6002631534;
  transform: rotate(182.3319402098deg);
  animation: drop-112 4.8189566478s 0.2505779342s infinite;
}

@keyframes drop-112 {
  100% {
    top: 110%;
    left: 95%;
  }
}
.confetti-113 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 91%;
  opacity: 0.7848205362;
  transform: rotate(253.5079398033deg);
  animation: drop-113 4.3860848688s 0.8966709613s infinite;
}

@keyframes drop-113 {
  100% {
    top: 110%;
    left: 105%;
  }
}
.confetti-114 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 9%;
  opacity: 0.6429407098;
  transform: rotate(25.1255571585deg);
  animation: drop-114 4.119369072s 0.8919847249s infinite;
}

@keyframes drop-114 {
  100% {
    top: 110%;
    left: 15%;
  }
}
.confetti-115 {
  width: 2px;
  height: 0.8px;
  background-color: #263672;
  top: -10%;
  left: 12%;
  opacity: 1.4071984966;
  transform: rotate(174.3562216158deg);
  animation: drop-115 4.4030721136s 0.9291000297s infinite;
}

@keyframes drop-115 {
  100% {
    top: 110%;
    left: 13%;
  }
}
.confetti-116 {
  width: 2px;
  height: 0.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 43%;
  opacity: 1.4879149197;
  transform: rotate(254.7854005017deg);
  animation: drop-116 4.5990464466s 0.406477364s infinite;
}

@keyframes drop-116 {
  100% {
    top: 110%;
    left: 53%;
  }
}
.confetti-117 {
  width: 7px;
  height: 2.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 74%;
  opacity: 0.9916267563;
  transform: rotate(73.4450696611deg);
  animation: drop-117 4.3368892818s 0.6573207128s infinite;
}

@keyframes drop-117 {
  100% {
    top: 110%;
    left: 84%;
  }
}
.confetti-118 {
  width: 3px;
  height: 1.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 90%;
  opacity: 0.8804341521;
  transform: rotate(39.5946886381deg);
  animation: drop-118 4.7622916393s 0.6559887495s infinite;
}

@keyframes drop-118 {
  100% {
    top: 110%;
    left: 100%;
  }
}
.confetti-119 {
  width: 7px;
  height: 2.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 81%;
  opacity: 1.0752609381;
  transform: rotate(61.4280689958deg);
  animation: drop-119 4.7388443974s 0.4762797278s infinite;
}

@keyframes drop-119 {
  100% {
    top: 110%;
    left: 88%;
  }
}
.confetti-120 {
  width: 1px;
  height: 0.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 79%;
  opacity: 1.3468903961;
  transform: rotate(284.471857783deg);
  animation: drop-120 4.2898644622s 0.74157825s infinite;
}

@keyframes drop-120 {
  100% {
    top: 110%;
    left: 82%;
  }
}
.confetti-121 {
  width: 1px;
  height: 0.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 47%;
  opacity: 0.8953365555;
  transform: rotate(196.7504720802deg);
  animation: drop-121 4.9924395163s 0.4658831371s infinite;
}

@keyframes drop-121 {
  100% {
    top: 110%;
    left: 61%;
  }
}
.confetti-122 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 19%;
  opacity: 1.3736251405;
  transform: rotate(356.8779046714deg);
  animation: drop-122 4.9770664984s 0.2082287309s infinite;
}

@keyframes drop-122 {
  100% {
    top: 110%;
    left: 23%;
  }
}
.confetti-123 {
  width: 2px;
  height: 0.8px;
  background-color: #d13447;
  top: -10%;
  left: 21%;
  opacity: 0.8826341331;
  transform: rotate(9.1091826134deg);
  animation: drop-123 4.7712630793s 0.2352722879s infinite;
}

@keyframes drop-123 {
  100% {
    top: 110%;
    left: 22%;
  }
}
.confetti-124 {
  width: 5px;
  height: 2px;
  background-color: #263672;
  top: -10%;
  left: 77%;
  opacity: 1.2721756284;
  transform: rotate(310.4141445263deg);
  animation: drop-124 4.7564118918s 0.127909689s infinite;
}

@keyframes drop-124 {
  100% {
    top: 110%;
    left: 81%;
  }
}
.confetti-125 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 3%;
  opacity: 1.3195438718;
  transform: rotate(31.2630082166deg);
  animation: drop-125 4.0377609499s 0.9858169424s infinite;
}

@keyframes drop-125 {
  100% {
    top: 110%;
    left: 7%;
  }
}
.confetti-126 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 99%;
  opacity: 1.2393310341;
  transform: rotate(242.4938790511deg);
  animation: drop-126 4.3824372817s 0.2268845995s infinite;
}

@keyframes drop-126 {
  100% {
    top: 110%;
    left: 104%;
  }
}
.confetti-127 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 19%;
  opacity: 0.5396815826;
  transform: rotate(209.5451832959deg);
  animation: drop-127 4.1140747676s 0.8434789627s infinite;
}

@keyframes drop-127 {
  100% {
    top: 110%;
    left: 30%;
  }
}
.confetti-128 {
  width: 5px;
  height: 2px;
  background-color: #ffbf00;
  top: -10%;
  left: 47%;
  opacity: 0.757103076;
  transform: rotate(267.3615386393deg);
  animation: drop-128 4.3338684639s 0.3968318174s infinite;
}

@keyframes drop-128 {
  100% {
    top: 110%;
    left: 62%;
  }
}
.confetti-129 {
  width: 4px;
  height: 1.6px;
  background-color: #ffbf00;
  top: -10%;
  left: 34%;
  opacity: 0.939131495;
  transform: rotate(182.850454783deg);
  animation: drop-129 4.672848507s 0.2916325799s infinite;
}

@keyframes drop-129 {
  100% {
    top: 110%;
    left: 42%;
  }
}
.confetti-130 {
  width: 7px;
  height: 2.8px;
  background-color: #d13447;
  top: -10%;
  left: 20%;
  opacity: 1.2776709537;
  transform: rotate(138.0028108652deg);
  animation: drop-130 4.4319611544s 0.7380381451s infinite;
}

@keyframes drop-130 {
  100% {
    top: 110%;
    left: 26%;
  }
}
.confetti-131 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 44%;
  opacity: 0.6784367935;
  transform: rotate(349.626813892deg);
  animation: drop-131 4.7608668916s 0.3178259633s infinite;
}

@keyframes drop-131 {
  100% {
    top: 110%;
    left: 54%;
  }
}
.confetti-132 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 86%;
  opacity: 1.2255390196;
  transform: rotate(47.4137149768deg);
  animation: drop-132 4.8473190371s 0.0580517014s infinite;
}

@keyframes drop-132 {
  100% {
    top: 110%;
    left: 91%;
  }
}
.confetti-133 {
  width: 3px;
  height: 1.2px;
  background-color: #263672;
  top: -10%;
  left: 48%;
  opacity: 1.4703424493;
  transform: rotate(9.1054888308deg);
  animation: drop-133 4.1258555493s 0.5069090908s infinite;
}

@keyframes drop-133 {
  100% {
    top: 110%;
    left: 51%;
  }
}
.confetti-134 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 18%;
  opacity: 0.5546095248;
  transform: rotate(135.6766775946deg);
  animation: drop-134 4.3814235982s 0.0112489477s infinite;
}

@keyframes drop-134 {
  100% {
    top: 110%;
    left: 28%;
  }
}
.confetti-135 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 73%;
  opacity: 0.8121805803;
  transform: rotate(52.5741515023deg);
  animation: drop-135 4.1916965673s 0.1141498132s infinite;
}

@keyframes drop-135 {
  100% {
    top: 110%;
    left: 79%;
  }
}
.confetti-136 {
  width: 2px;
  height: 0.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 65%;
  opacity: 1.4337410867;
  transform: rotate(315.7386736962deg);
  animation: drop-136 4.2176366114s 0.887677813s infinite;
}

@keyframes drop-136 {
  100% {
    top: 110%;
    left: 69%;
  }
}
.confetti-137 {
  width: 3px;
  height: 1.2px;
  background-color: #263672;
  top: -10%;
  left: 11%;
  opacity: 1.3531034516;
  transform: rotate(123.6720428686deg);
  animation: drop-137 4.4589649406s 0.9191186176s infinite;
}

@keyframes drop-137 {
  100% {
    top: 110%;
    left: 22%;
  }
}
.confetti-138 {
  width: 8px;
  height: 3.2px;
  background-color: #263672;
  top: -10%;
  left: 7%;
  opacity: 1.2468778261;
  transform: rotate(147.7010621214deg);
  animation: drop-138 4.5742518032s 0.8217566201s infinite;
}

@keyframes drop-138 {
  100% {
    top: 110%;
    left: 12%;
  }
}
.confetti-139 {
  width: 8px;
  height: 3.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 50%;
  opacity: 1.3533907714;
  transform: rotate(28.4019576381deg);
  animation: drop-139 4.8543635704s 0.238758439s infinite;
}

@keyframes drop-139 {
  100% {
    top: 110%;
    left: 57%;
  }
}
.confetti-140 {
  width: 3px;
  height: 1.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 2%;
  opacity: 0.6907252996;
  transform: rotate(175.1081581242deg);
  animation: drop-140 4.0081700451s 0.699871159s infinite;
}

@keyframes drop-140 {
  100% {
    top: 110%;
    left: 10%;
  }
}
.confetti-141 {
  width: 2px;
  height: 0.8px;
  background-color: #263672;
  top: -10%;
  left: 21%;
  opacity: 0.7379225795;
  transform: rotate(332.0507042588deg);
  animation: drop-141 4.3701796002s 0.4195591033s infinite;
}

@keyframes drop-141 {
  100% {
    top: 110%;
    left: 35%;
  }
}
.confetti-142 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 24%;
  opacity: 0.6735517858;
  transform: rotate(335.8064940031deg);
  animation: drop-142 4.5603876476s 0.9868890779s infinite;
}

@keyframes drop-142 {
  100% {
    top: 110%;
    left: 25%;
  }
}
.confetti-143 {
  width: 7px;
  height: 2.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 74%;
  opacity: 0.8436277786;
  transform: rotate(78.0298783005deg);
  animation: drop-143 4.5566496765s 0.5585283297s infinite;
}

@keyframes drop-143 {
  100% {
    top: 110%;
    left: 89%;
  }
}
.confetti-144 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 15%;
  opacity: 0.7282251851;
  transform: rotate(323.2756190571deg);
  animation: drop-144 4.2224788552s 0.7497545942s infinite;
}

@keyframes drop-144 {
  100% {
    top: 110%;
    left: 17%;
  }
}
.confetti-145 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 70%;
  opacity: 1.2067500337;
  transform: rotate(203.3516577273deg);
  animation: drop-145 4.0393064238s 0.2914457098s infinite;
}

@keyframes drop-145 {
  100% {
    top: 110%;
    left: 72%;
  }
}
.confetti-146 {
  width: 5px;
  height: 2px;
  background-color: #ffbf00;
  top: -10%;
  left: 74%;
  opacity: 1.067444524;
  transform: rotate(235.3829525324deg);
  animation: drop-146 4.9856098449s 0.5829633679s infinite;
}

@keyframes drop-146 {
  100% {
    top: 110%;
    left: 83%;
  }
}
.confetti-147 {
  width: 5px;
  height: 2px;
  background-color: #d13447;
  top: -10%;
  left: 29%;
  opacity: 0.8729261789;
  transform: rotate(317.9308413695deg);
  animation: drop-147 4.9255549096s 0.8004823635s infinite;
}

@keyframes drop-147 {
  100% {
    top: 110%;
    left: 41%;
  }
}
.confetti-148 {
  width: 1px;
  height: 0.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 27%;
  opacity: 1.2175564344;
  transform: rotate(245.0711440489deg);
  animation: drop-148 4.1558784536s 0.405168525s infinite;
}

@keyframes drop-148 {
  100% {
    top: 110%;
    left: 42%;
  }
}
.confetti-149 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 97%;
  opacity: 0.879962832;
  transform: rotate(355.9416910802deg);
  animation: drop-149 4.1797703143s 0.3350169095s infinite;
}

@keyframes drop-149 {
  100% {
    top: 110%;
    left: 111%;
  }
}
.confetti-150 {
  width: 3px;
  height: 1.2px;
  background-color: #263672;
  top: -10%;
  left: 79%;
  opacity: 0.8563174769;
  transform: rotate(151.3798845523deg);
  animation: drop-150 4.8603547722s 0.2533286769s infinite;
}

@keyframes drop-150 {
  100% {
    top: 110%;
    left: 82%;
  }
}
h2 {
  font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
  color: #000;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 860;
}

h3 {
  color: #000;
  font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 3rem;
  font-style: normal;
  font-weight: 500;
}

h4 {
  color: #000;
  font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 3rem;
  font-style: normal;
  font-weight: 400;
}

h6 {
  font-family: "Really Sans Thin", serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

h7 {
  color: #000;
  font-family: "Inter", serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 112.5% */
}

h5 {
  font-weight: 700;
  font-size: 20px;
}

.toast-error {
  border-radius: 0.625rem;
  background: #CA3521;
  /* Light/elevation.shadow.overlay */
  box-shadow: 0px 8px 12px 0px rgba(9, 30, 66, 0.15), 0px 0px 1px 0px rgba(9, 30, 66, 0.31);
  color: #FFF;
  width: 23rem;
  padding: 1rem;
  align-items: flex-start;
  /* .Internal/Flag/.Flag title */
  font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 550;
}

.toast-success {
  border-radius: 0.625rem;
  /* Light/elevation.shadow.overlay */
  box-shadow: 0px 8px 12px 0px rgba(9, 30, 66, 0.15), 0px 0px 1px 0px rgba(9, 30, 66, 0.31);
  color: var(--light-color-text-inverse, #FFF);
  width: 23rem;
  padding: 1rem;
  align-items: flex-start;
  background: #377F56;
  font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 550;
}

.toast-info {
  border-radius: 0.625rem;
  /* Light/elevation.shadow.overlay */
  box-shadow: 0px 8px 12px 0px rgba(9, 30, 66, 0.15), 0px 0px 1px 0px rgba(9, 30, 66, 0.31);
  color: black;
  width: 23rem;
  padding: 1rem;
  align-items: flex-start;
  font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 550;
  background: rgb(255, 255, 255);
}

.question-html-wrapper {
  max-width: 100%;
}
.question-html-wrapper img {
  min-width: 100%;
}
.question-html-wrapper figure {
  min-width: 100%;
  display: flex;
  justify-content: center;
}
.question-html-wrapper p {
  color: black;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  max-width: 100%;
  word-break: break-word;
  margin-bottom: 32px;
}

.answer-html-wrapper {
  max-width: 100%;
}
.answer-html-wrapper img {
  min-width: 100%;
}
.answer-html-wrapper figure {
  min-width: 100%;
  display: flex;
  justify-content: center;
}
.answer-html-wrapper p {
  color: black;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  max-width: 100%;
  word-break: break-word;
}

.exam-selection-container {
  margin-top: 1.5rem;
  padding: 0;
}

.side-toolbar {
  background-color: #FBF9F9;
  max-width: 344px;
  min-width: 344px;
  display: flex;
  padding: 1rem 0.5rem;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;
  align-self: stretch;
  min-height: 100%;
}
.side-toolbar * {
  color: black;
}

.category-selector {
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  width: 100%;
  font-size: 0.9rem;
  gap: 0.5rem;
}

.main-section {
  width: 100%;
  margin-top: 4rem;
  display: flex;
  height: auto;
  justify-content: center;
}

.paper {
  display: flex;
  max-width: 47.375rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  padding: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 0.25rem;
  border: 1px solid var(--border-border-opaque, #E7E5E4);
  background: #FFF;
  height: auto;
  text-wrap: wrap;
  /* dropShadow */
  box-shadow: 0px 8px 12px 0px rgba(9, 30, 66, 0.15), 0px 0px 1px 0px rgba(9, 30, 66, 0.31);
}
.paper * {
  text-align: left;
}

.essay-style-wrapper > p {
  margin: 0;
}

.explanation-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  width: 100%;
  margin-top: 24px;
  padding-right: 8px;
  padding-left: 8px;
}

.explanation-sidebar-header {
  color: var(--content-content-tertiary, #78716C);
  font-family: Inter, serif;
  font-size: 12px;
  margin: 0rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 0.825rem */
}

.explanation-sidebar-content {
  margin-top: 10px;
  color: var(--content-content-primary, #000);
  font-family: Inter, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.125rem */
  height: 100%;
}

.setup-label {
  color: var(--content-content-secondary, #292524);
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.125rem; /* 180% */
}

.tooltip-tag {
  display: flex;
  padding: 0.3125rem 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 1.9375rem;
  border: 1px solid #F32020;
  background: rgba(243, 32, 32, 0.1);
}

.essay-tooltip {
  display: flex;
  padding: 0.9375rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
  width: 24.9375rem;
  font-size: 14px;
  font-family: Inter tight, serif;
  border-radius: 0.1875rem;
  background: white;
  /* Light/elevation.shadow.overlay */
  box-shadow: 0px 8px 12px 0px rgba(9, 30, 66, 0.15), 0px 0px 1px 0px rgba(9, 30, 66, 0.31);
}

.submit-essay-header-div {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFF;
  width: auto;
  min-height: 48px;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}

.submit-essay-generated-prompt-styling {
  color: var(--content-content-tertiary, #78716C);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 200% */
}

.ql-editor {
  padding: 1.5rem;
  border: 1px solid var(--border-border-opaque, #E7E5E4);
  font-size: 16px;
  font-family: "Inter", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background: #FFF;
  border-radius: 0.25rem;
  /* dropShadow */
  box-shadow: 0px 8px 12px 0px rgba(9, 30, 66, 0.15), 0px 0px 1px 0px rgba(9, 30, 66, 0.31);
  line-height: 1.5rem;
}

.quill > .ql-container > .ql-editor.ql-blank::before {
  padding: 0;
  margin-left: 0.7rem;
  font-size: 16px;
  font-family: "Inter", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #5E5E5E;
  font-style: normal;
}

.toggle-text {
  margin: 0;
  text-align: right;
  color: var(--content-content-tertiary, #78716C);
  font-family: Inter, serif;
  font-size: 0.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 0.55rem */
  align-content: center;
  align-self: center;
}

.toast-error {
  border-radius: 0.625rem;
  background: #CA3521;
  box-shadow: 0px 8px 12px 0px rgba(9, 30, 66, 0.15), 0px 0px 1px 0px rgba(9, 30, 66, 0.31);
  color: #FFF;
  width: 23rem;
  padding: 1rem;
  align-items: flex-start;
  font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 550;
}

.dotted-border {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='1' stroke-dasharray='5%2c 5' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.vertical-divider-padding-less {
  padding: 0 !important;
  margin: 0 !important;
}

.prompt-hover-button {
  text-align: left;
  font-weight: lighter;
  padding: 0;
  border: none;
  background: none;
}

.writing-tool-button-base {
  display: flex;
  font-size: 0.875rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
  color: #292524;
  background-color: #F5F5F4;
  border-radius: 0.25rem;
  height: 2rem;
  border: 1px solid transparent;
  font-weight: 500;
  font-family: inherit;
  cursor: pointer;
  transition: border-color 0.25s;
  max-width: 100px;
  max-height: 32px;
  padding-left: 12px;
  padding-right: 12px;
}

.category-button {
  border-radius: 0.25rem;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 14px;
  font-weight: 400;
  max-height: 40px;
  font-family: inherit;
  cursor: pointer;
  transition: border-color 0.25s;
}

.category-selector .category-button:hover {
  background-color: #E7E5E4;
}

/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the ‘X’ from Chrome */
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  display: none;
}

/*# sourceMappingURL=custom.css.map */
