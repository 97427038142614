/* width */
::-webkit-scrollbar {
  width: 0.5rem;
}

/* Track */
/* Handle */
::-webkit-scrollbar-thumb {
  width: 0.5rem;
  flex-shrink: 0;
  border-radius: 6.25rem;
  background: var(--colour-system-components-border-separator, #ECF1F2);
}

:root {
  scrollbar-color: var(--colour-system-components-border-separator, #ECF1F2);
  scrollbar-width: thin;
}

